<template>
    <div class="reset__container">
        <base-dialog :show="showDialog" :title="dialogTitle" :text="dialogText">
            <template #actions>
                <v-btn :color="dialogButtonColor" @click="closeDialog()">
                    {{
                        dialogSuccess
                            ? $t("General.ok")
                            : $t("General.tryAgain")
                    }}
                </v-btn>
            </template>
        </base-dialog>
        <v-card class="reset_form__container" max-width="500" min-height="500">
            <v-img
                src="../../assets/logo-agrobofood-small.svg"
                max-height="120"
                max-width="120"
                alt="agrobofood logo"
                class="logo"
            >
            </v-img>
            <v-card-title class="d-block">
                <v-row>
                    <v-col cols="12" class="pa-0">
                        <p class="text-center pa-2 mb-3">
                            {{ $t("ResetPass.title") }}
                        </p>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-form class="reset_form" @submit.prevent="handleResetPassword()">
                <v-card-text>
                    <v-text-field
                        v-model.trim="password"
                        :error-messages="passwordErrors"
                        :append-icon="
                            showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'
                        "
                        :type="showPassword ? '' : 'password'"
                        :label="$t('RegisterForm.registerForm.passwordLabel')"
                        required
                        outlined
                        @input="$v.password.$touch()"
                        @blur="$v.password.$touch()"
                        @click:append="showPassword = !showPassword"
                    ></v-text-field>
                    <v-text-field
                        v-model.trim="passwordConfirmation"
                        :error-messages="passwordConfErrors"
                        :append-icon="
                            showPasswordConf ? 'fas fa-eye' : 'fas fa-eye-slash'
                        "
                        :type="showPasswordConf ? '' : 'password'"
                        :label="
                            $t('RegisterForm.registerForm.repeatPasswordLabel')
                        "
                        required
                        outlined
                        @input="$v.passwordConfirmation.$touch()"
                        @blur="$v.passwordConfirmation.$touch()"
                        @click:append="showPasswordConf = !showPasswordConf"
                    ></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="primary"
                        depressed
                        type="submit"
                        :disabled="formIsValid"
                        :loading="resetPasswordStatus_Pending"
                    >
                        {{ $t("General.submit") }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </div>
</template>

<script>
// API layer Imports
import { withAsync } from "../../helpers/withAsync";
import { apiStatus } from "../../api/constants/apiStatus.js";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus.js";
import { resetPassword } from "../../api/authApi.js";

//Vuelidate Imports
import { validationMixin } from "vuelidate";
import { required, sameAs, minLength } from "vuelidate/lib/validators";

//Component Imports
import BaseDialog from "../../components/base/BaseDialog.vue";

export default {
    name: "ResetPassword",

    mixins: [validationMixin],

    validations: {
        password: {
            required,
            minLengthValue: minLength(3),
        },
        passwordConfirmation: {
            required,
            sameAsPassword: sameAs("password"),
        },
    },

    components: {
        BaseDialog,
    },

    data() {
        return {
            resetPasswordStatus: apiStatus.Idle,
            password: "",
            passwordConfirmation: "",
            showPassword: false,
            showPasswordConf: false,
            showDialog: false,
            dialogTitle: "",
            dialogText: "",
            dialogSuccess: false,

            userToken: "",
        };
    },

    computed: {
        ...apiStatusComputed("resetPasswordStatus"),

        /**
         * Computed functions used to validate inputs with vuelidate.
         * @return {errors} return errors that are shown on blur and on input
         */

        passwordErrors() {
            const errors = [];
            if (!this.$v.password.$dirty) return errors;
            !this.$v.password.minLengthValue &&
                errors.push(this.$tc("ErrorMessages.minLength", 3));
            !this.$v.password.required &&
                errors.push(this.$t("ErrorMessages.required"));
            return errors;
        },

        passwordConfErrors() {
            const errors = [];
            if (!this.$v.passwordConfirmation.$dirty) return errors;
            !this.$v.passwordConfirmation.required &&
                errors.push(this.$t("ErrorMessages.required"));
            !this.$v.passwordConfirmation.sameAsPassword &&
                errors.push(this.$t("ErrorMessages.samePassword"));
            return errors;
        },

        formIsValid() {
            return this.$v.$invalid;
        },

        dialogButtonColor() {
            return this.dialogSuccess ? "primary" : "error";
        },
    },

    methods: {
        /**
         * Sends a call to the backend holding the needed data as payload. Returns success or unsuccessful message
         * based on the backend response for reseting the user password
         */

        async handleResetPassword() {
            this.resetPasswordStatus = apiStatus.Pending;
            const payload = {
                token: this.userToken,
                password: this.password,
                passwordConfirmation: this.passwordConfirmation,
            };

            const { response, error } = await withAsync(resetPassword, payload);

            if (error) {
                this.resetPasswordStatus = apiStatus.Error;
                this.dialogTitle = this.$t("ResetPass.errorDialogTitle");
                this.dialogText = error.response.data;
                this.dialogSuccess = false;
                this.showDialog = true;
                return;
            }

            this.resetPasswordStatus = apiStatus.Success;
            this.dialogTitle = this.$t("ResetPass.successDialogTitle");
            this.dialogText = this.$t("ResetPass.successDialogText");
            this.dialogSuccess = true;
            this.showDialog = true;
        },

        closeDialog() {
            this.showDialog = false;
            if (this.dialogSuccess) {
                this.$router.replace("/login");
            } else {
                this.$router.replace("/forgot-password");
            }
        },
    },

    /**
     * On create takes the tkn(token) route query
     * and sets the token variable that will be sent
     * to the backend to find the user and change his pasword
     *
     * If the tkn query does not exist the user is redirected to the login page
     */

    created() {
        this.apiStatus = apiStatus;

        if (this.$route.query.tkn) {
            this.userToken = this.$route.query.tkn;
            return;
        }

        this.$router.replace("/not-found");
    },
};
</script>

<style scoped>
.reset__container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    background-image: linear-gradient(60deg, #42855b 0%, #e8e6d3 100%);
    background-size: 400% 400%;
    opacity: 0.8;
    animation: animateBackground 10s ease infinite;
}

.reset__container::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    z-index: -2 !important;
    background: url("../../assets/pattern-circles.svg");
}

.logo {
    transform: translate(-50px, -50px);
}
.reset_form__container {
    width: 90%;
    height: 30%;
}

.reset_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

@keyframes animateBackground {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
</style>
